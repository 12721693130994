import {
  brownUniversity,
  cambridgeUniversity,
  columbiaUniversity,
  harvardUniversity,
  montrealUniversity,
  oxfordUniversity,
  virginiaUniversity,
  waterlooUniversity,
  westernUniversity,
  yaleUniversity
} from '../Images'

export const Universities = [
  { icon: waterlooUniversity },
  { icon: harvardUniversity },
  { icon: yaleUniversity },
  { icon: montrealUniversity },
  { icon: westernUniversity },
  { icon: brownUniversity },
  { icon: virginiaUniversity },
  { icon: columbiaUniversity },
  { icon: oxfordUniversity },
  { icon: cambridgeUniversity }
]
