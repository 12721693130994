export const ServiceSteps = [
    {
        count:1,
        desc:"Reach out to us"
    },
    {
        count:2,
        desc:"Get option of top universities"
    },
    {
        count:3,
        desc:"Process admission application"
    },
    {
        count:4,
        desc:"Apply for student visa"
    },
    {
        count:5,
        desc:"Get departure checklist"
    }
]