import React, { useEffect, useState } from 'react'
import { RiCheckFill } from 'react-icons/ri'
import { NavLink, useLocation } from 'react-router-dom'
import {
  aboutCanadaLogo,
  canadaBannerLogo,
  canadaBenefits,
  permanentResidency,
  studyVisa,
  visitorVisa,
  workPermit
} from '../res/Images'
import { ReasonCanada } from '../res/Statics/ReasonCanada'
import '../Stylesheet/Country.css'
import Footer from './Footer'
import Navbar from './Navbar'

export default function Canada () {
  const location = useLocation()
  const [Loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div>
      <Navbar bannerImage={canadaBannerLogo}>
        <p className='bannerText'>EASILY STUDY, WORK & SETTLE</p>
        <p className='bannerSecondLineText'>
          IN CANADA
        </p>
        <NavLink to='/contact' className='paddingblueBgBtn'>
          Begin
        </NavLink>
      </Navbar>

      <div className='rowColumn section centered'>
        <div className='descContentContainer'>
          <p className='descContentTitle'>About Canada</p>
          <p className='descContent'>
            Canada consistently ranks as one of the best countries in the world.
            Whether you choose to study in one of Canada’s large, vibrant cities
            or settle on a small campus in a warm, welcoming community, your
            experience will be one that will shape your life. It may lead to a
            career and a future in Canada, or better career prospects at home.
            At the very least, it will give you access to Canada’s four
            beautiful seasons, wide-open spaces, abundant wildlife,
            multicultural diversity, clean environment and incredible quality of
            life.
          </p>
        </div>
        <img src={aboutCanadaLogo} className='descLogo' />
      </div>

      <div className='serviceContainer'>
        <p className='headerDescText'>Services</p>
        <p className='headerText' style={{ marginBottom: 20 }}>
          Explore What We Do
        </p>

        <div className='service'>
          <div className='card'>
            <div className='layer'> </div>
            <div className='serviceContent'>
              <img src={studyVisa} className='serviceIcon' />
              <p className='cardHeaderText'>Study Visa</p>
              <p className='cardContentText'>
                Study and settle in canada with their Study to PR pathways
              </p>
            </div>
          </div>

          <div className='card'>
            <div className='layer'></div>
            <div className='serviceContent'>
              <img src={visitorVisa} className='serviceIcon' />
              <p className='cardHeaderText'>Visitor Visa</p>
              <p className='cardContentText'>
                Meet your family member or explore the beautiful nature of canada
              </p>
            </div>
          </div>
       
          <div className='card'>
            <div className='layer'> </div>
            <div className='serviceContent'>
            <img src={workPermit} className='serviceIcon' />
              <p className='cardHeaderText'>Work Permit</p>
              <p className='cardContentText'>if you are sponsored by any
                canadian company for a job</p>
            </div>
          </div>

          <div className='card'>
            <div className='layer'> </div>
            <div className='serviceContent'>
              <img src={permanentResidency} className='serviceIcon' />
              <p className='cardHeaderText'>Permanent Residency</p>
              <p className='cardContentText'>Apply for a permanent residency via PNP or Express Entry</p>
            </div>
          </div>
        </div>
      </div>

      <div className='benefitContainer'>
        <img src={canadaBenefits} className='descLogo' />
        <div className='descContentContainer'>
          <div>
            <p className='benefitSubtitle'>UNLOCK YOUR CAREER DREAM</p>
            <p className='descContentTitle'>Reasons to study in Canada</p>

            <p className='descContent'>
              Canada has developed a first-rate education system with high
              standards. A Canadian degree, diploma or certificate is recognized
              globally as being equivalent to those from the United States and
              Commonwealth countries.
            </p>
          </div>
          {ReasonCanada.map((item, index) => {
            return (
              <div className='reasonContainer'>
                <RiCheckFill
                  className='checkIcon'
                  color='#0f0d72ca'
                  size={20}
                />
                <p className='reasonText'>{item.title}</p>
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </div>
  )
}
