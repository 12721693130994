import React,{useEffect, useState} from 'react'
import { RiCheckFill } from 'react-icons/ri'
import { aboutUkLogo, studyVisa, ukBannerLogo, ukBenefits, visitorVisa, workPermit } from '../res/Images'
import Navbar from './Navbar'
import { ReasonUk } from '../res/Statics/ReasonUk'
import { NavLink, useLocation } from 'react-router-dom'
import '../Stylesheet/Country.css'
import Footer from './Footer'

export default function Uk () {

  const location = useLocation()
  const [Loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },1000)
    window.scrollTo(0,0)
  },[location])

  return (
    <div>
      <Navbar bannerImage={ukBannerLogo}>
        <p className='bannerText'>STUDY IN UNITED STATE</p>
        <p className='bannerSecondLineText'>
          BEGIN YOUR APPLICATION TODAY
        </p>
        <NavLink className='paddingblueBgBtn' to='/contact'>
          Begin
        </NavLink>
      </Navbar>

      <div className='rowColumn section centered'>
        <div className='descContentContainer'>
          <p className='descContentTitle'>About United Kingdom</p>
          <p className='descContent'>
            The United Kingdom (UK) is reputed for its excellent quality of
            education, interactive teaching methodology, top ranking
            universities and great student satisfaction. Universities in the UK
            consistently rank in the world university rankings such as the QS
            World Rankings, Times Higher Education Ranking, and Academic Ranking
            of World Universities. Further, degrees earned from the country are
            globally recognised and preferred by employers all over the world.
          </p>
        </div>
        <img src={aboutUkLogo} className='descLogo' />
      </div>

      <div className='serviceContainer'>
        <p className='headerDescText'>Services</p>
        <p className='headerText' style={{ marginBottom: 20 }}>
          Explore What We Do
        </p>

        <div className='feature'>
          <div className='card'>
            <div className='layer'> </div>
            <div className='featureContent'>
              <img src={studyVisa} className='serviceIcon' />
              <p className='cardHeaderText'>Study Visa</p>
              <p className='cardContentText'>
              Study and settle in UK with their Study to PR pathways
              </p>
            </div>
          </div>

          <div className='card'>
            <div className='layer'></div>
            <div className='featureContent'>
              <img src={visitorVisa} className='serviceIcon' />
              <p className='cardHeaderText'>Visitor Visa</p>
              <p className='cardContentText'>Meet your family member or explore the beautiful nature of
                UK</p>
            </div>
          </div>

          <div className='card'>
            <div className='layer'></div>
            <div className='featureContent'>
              <img src={workPermit} className='serviceIcon' />
              <p className='cardHeaderText'>Work Permit</p>
              <p className='cardContentText'>if you are sponsored by any
                UK's company for a job</p>
            </div>
          </div>

        </div>
      </div>

      <div className='benefitContainer'>
        <img src={ukBenefits} className='descLogo' />
        <div className='descContentContainer'>
          <div>
            <p className='benefitSubtitle'>UNLOCK YOUR CAREER DREAM</p>
            <p className='descContentTitle'>Reasons to study in UK</p>

            <p className='descContent'>
              The UK is a popular destination for higher studies for students
              from around the world and is home to three of the top 10
              universities in the world, according to the World University
              Rankings. A degree that you gain from an UK university will be
              recognised internationally by universities, employers and
              government bodies.
            </p>
          </div>
          {ReasonUk.map((item, index) => {
            return (
              <div className='reasonContainer'>
                <RiCheckFill
                  className='checkIcon'
                  color='#0f0d72ca'
                  size={20}
                />
                <p className='reasonText'>{item.title}</p>
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </div>
  )
}
