import React, { useEffect } from 'react'
import '../Stylesheet/Legal.css'
import { useLocation } from 'react-router-dom'
import Navbar from './Navbar'
import { privacyBannerLogo } from '../res/Images'
import Footer from './Footer'

export default function Privacy() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  return (
    <div>
      <div className='legalBanner'>
        <p className='legalBannerText'>PRIVACY POLICY</p>
        <img src={privacyBannerLogo} className='legalBannerImage' />
      </div>
      <div className='legalSection'>
        <p className='legalDesc'>
          At Honest Advisor, accessible from https://www.honestadvisor.in/, We
          understand your concern about information privacy and care of online
          safety. This privacy policy describe how and what we collect, store
          and share and use information provided by you in entire process
          through website www.honestadvisor.in.
        </p>
        <p className='legalDesc'>
          If you have any query or require more information about our Privacy
          Policy, please do not hesitate to contact us.
        </p>
        <p className='legalHeader'>Consent:</p>
        <p className='legalDesc'>
          {' '}
          By visiting on our website or you agree to be bound by the terms and
          conditions of this Privacy Policy. If you do not agree please do not
          use or access our Website.
        </p>
        <p className='legalHeader'>Privacy Policy</p>
        <p className='legalDesc'>
          This privacy policy is formulated in accordance with the Information
          Technology Act 2000 (“IT Act”) read along with the information
          Technology (Reasonable Security Practices and Procedure and Sensitive
          Personal Data or Information) Rules, 2011 (“IT Act”). and rules
          thereunder, and is published in accordance with the provisions of Rule
          3 (1) of the Information Technology (Intermediaries Guidelines and
          Digital Media Ethics code) Rules, 2021, which mandates publishing of
          rules and regulations, privacy policy and terms of use for access or
          usage of our services. This electronic record is generated by a
          computer system and does not require any physical or digital
          signature. [Note: Our privacy policy is subject to change at any time
          without notice. To make sure you are aware of any changes, please
          review this policy periodically.]
        </p>
        <p className='legalHeader'>1. Collection of Information:</p>
        <p className='legalDesc'>
          When you use our Platform, we collect and store your information which
          is provided by you from time to time. In general, you can browse the
          Platform without telling us who you are or revealing any personal
          information about yourself. Once you give us your personal
          information, you are not anonymous to us. Where possible, we indicate
          which fields are required and which fields are optional. You always
          have the option to not provide information by choosing not to use a
          particular service, product or feature on the Platform. We may track
          your behavior, preferences, and other information that you choose to
          provide on our platform. We use this information to do internal
          research on our users' demographics, interests, and behavior to better
          understand, protect and serve our users. This information is compiled
          and analyzed on an aggregated basis. This information may include the
          URL that you just came from (whether this URL is on our Platform or
          not), which URL you next go to (whether this URL is on our Platform or
          not), your computer browser information, and your IP address. We do
          use your contact information to communicate with you for providing the
          best service it can to align you with the educational institutions,
          scholarship providers, Education loan Provider, test providers and
          other service providers of your choice. Especially, the legitimate
          interest services are that we, or an educational institution or other
          service provider concerned can respond in an effective and timely
          manner to any questions or complaints you might have. We may also
          maintain a record of your interest and acquire information about you
          for our present and/or future affiliates.
        </p>
        <p className='legalHeader'>
          2. Personal Information and Sensitive Personal data or Information:
        </p>
        <p className='legalDesc'>
          The IT Act and the IT Rules defines personal information as follows:
          means any information that relates to natural person, which either
          directly or indirectly, in combination with other information
          available or likely to be available with a body corporate is capable
          of identifying such person. The IT act and the IT rules define
          sensitive personal data and information of a person as meaning such
          personal information which consist of information related to: Password
          Financial Information such as Bank Account, debit/credit card
          Physical, psychological and mental health condition Medical record
          Biometric Information Any detail related to the above as provided to
          the body corporate for providing a service and Any of the information
          received under each of the heads by the body corporates for
          processing, or to be stored or processed under a lawful contract
          Provided that, any information that is freely available and accessible
          in public domain or furnished under the Right to information Act, 2005
          or any other law for the time being in force shall not be regarded as
          sensitive personal data or information for the purpose of IT act
          rules.
        </p>
        <p className='legalHeader'>3. Cookies</p>
        <p className='legalDesc'>
          We use data collection devices such as "cookies" on certain pages of
          the Platform to help analyze our web page flow, measure promotional
          effectiveness, and promote trust and safety. "Cookies" are small files
          placed on your hard drive that assist us in providing our services.
          Cookies do not contain any of your personal information. We offer
          certain features that are only available through the use of a
          "cookie". We also use cookies to allow you to enter your password less
          frequently during a session. Cookies can also help us provide
          information that is targeted to your interests. Most cookies are
          "session cookies," meaning that they are automatically deleted from
          your hard drive at the end of a session. You are always free to
          decline/delete our cookies if your browser permits, although in that
          case you may not be able to use certain features on the Platform and
          you may be required to re-enter your password more frequently during a
          session. Additionally, you may encounter "cookies" or other similar
          devices on certain pages of the Platform that are placed by third
          parties. We do not control the use of cookies by third parties. We use
          cookies from third-party partners such as Google Analytics for
          marketing and analytical purposes.{' '}
        </p>
        <p className='legalDesc'>
          Google Analytics help us understand how our customers use the site.
          You can read more about how Google uses your Personal Information
          here: https://www.google.com/intl/en/policies/privacy/. You can also
          opt-out of Google Analytics
          here: https://tools.google.com/dlpage/gaoptout.
        </p>
      </div>
      <Footer/>
    </div>
  )
}
