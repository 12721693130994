import React,{useEffect} from 'react'
import { serviceBannerLogo } from '../res/Images'
import Navbar from './Navbar'
import '../Stylesheet/Services.css'
import { ServiceSteps } from '../res/Statics/ServiceSteps'
import { Perks } from '../res/Statics/PerkItems'
import { ServiceItems } from '../res/Statics/ServiceItems'
import { NavLink, useLocation } from 'react-router-dom'
import Footer from './Footer'

export default function Services () {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div>
      <Navbar bannerImage={serviceBannerLogo}>
        <p className='bannerText'>TURN YOUR AMBITION INTO</p>
        <p className='bannerText'>A SUCCESS STORY</p>
      </Navbar>

      <div className='rowColumn section'>
        <div className='descContentContainer'>
          <p className='descContentTitle'>
            Looking for your forthcoming future?
          </p>
          <p className='descContent'>
            have your ambition of study abroad doesn't let you asleep? If so,
            its time to turn your ambition into success story. Lets build your
            career together. Get easy education loan, forex services, top
            universities option, admission assistance and many more. You can
            also find study abroad scholarship. Get all services under one roof
            to make your process smooth. We are always availed to consult you on
            taking your higher education to next level in the competitive world.
          </p>
        </div>
        <div className='serviceStepContainer'>
          {ServiceSteps.map((item, index) => {
            return (
              <div className='serviceStep'>
                <p className='serviceStepCount'>{item.count}</p>
                <p className='serviceStepText'>{item.desc}</p>
              </div>
            )
          })}
        </div>
      </div>

      <div className='serviceContainer'>
        <p className='headerText'>Perks of Honest Advisor</p>
        <p className='headerDescText'>
          Make your journey hassle-free, Get all services under one roof
        </p>
        <div className='perkContainer'>
          {Perks.map((item, index) => {
            return (
              <div className='perkCard'>
                <img src={item.icon} className='perkIcon' />
                <p className='perkText'>{item.title}</p>
              </div>
            )
          })}
        </div>
      </div>

      <div className='section'>
        <p className='headerDescText'>Our Services</p>
        <p className='headerText'>How Do We Work</p>

        <div className='serviceItemsContainer'>
          {ServiceItems.map((item, index) => {
            return (
              <div className='serviceItemCard'>
                <img src={item.Icon} className='serviceItemIcon' />
                <p className='serviceItemHeader'>{item.Name}</p>
                <p className='serviceItemDesc'>{item.Desc}</p>
              </div>
            )
          })}
        </div>
      </div>

      <div className='foundContainer'>
        <p className='foundTitle'>
          Haven't found what you are looking for? Feel free to contact
        </p>
        <p className='foundDesc'>
          We always try to provide you life-changing professional experience
        </p>

        <NavLink to='/contact' className='foundBtn'>
          Enquiry Now
        </NavLink>
      </div>
      <Footer />
    </div>
  )
}
