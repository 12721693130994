import { NavLink, useLocation } from 'react-router-dom'
import {
  canadaCountry,
  canadaFlag,
  countryBannerLogo,
  countryDescLogo,
  ukCountry,
  ukFlag,
  usCountry,
  usFlag
} from '../res/Images'
import { Universities } from '../res/Statics/Universities'
import Navbar from './Navbar'
import '../Stylesheet/Country.css'
import React,{useEffect} from 'react'
import Footer from './Footer'

export default function Country () {
  
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  
  return (
    <div>
      <Navbar bannerImage={countryBannerLogo}>
        <p className='bannerText'>FIND A WAY TO FLY TOWARDS</p>
        <p className='bannerSecondLineText' >
          YOUR DREAM
        </p>
        <NavLink to='/contact' className='paddingblueBgBtn'>
          Book A Consultation
        </NavLink>
      </Navbar>

      <div className='section'>
        <p className='headerText'>Immigration - Choose Your Country</p>
        <p className='headerDescText'>
          Apply to study, travel, work or immigrate to Canada, US & UK
        </p>

        <div className='rowColumnCountry'>
          <div className='countryContainer'>
          <img src={canadaFlag} className='countryFlag' />
            <img className='countries' src={canadaCountry} />
            <p className='countryName'>Canada</p>
            <p className='countryDesc'>
              Canada consistently ranks as one of the best countries in the
              world. Whether you choose to study ....
              <NavLink to='/country/canada' className='readmoreLink'>
                Read more
              </NavLink>
            </p>
          </div>
          <div className='countryContainer'>
          <img src={ukFlag} className='countryFlag' />
            <img className='countries' src={ukCountry} />
            <p className='countryName'>United Kingdom</p>
            <p className='countryDesc'>
              The United Kingdom (UK) is reputed for its excellent quality of
              education, interactive teaching ....
              <NavLink to='/country/uk' className='readmoreLink'>
                Read more
              </NavLink>
            </p>
          </div>
          <div className='countryContainer'>
          <img src={usFlag} className='countryFlag' />
            <img className='countries' src={usCountry} />
            <p className='countryName'>United State</p>
            <p className='countryDesc'>
              The United States is known for its higher education opportunities,
              which explains why ....{' '}
              <NavLink to='/country/us' className='readmoreLink'>
                Read more
              </NavLink>
            </p>
          </div>
        
        </div>
      </div>


      <div className='assessmentContainer'>
        <p className='assessmentText'>Free Profile Assessment</p>
        <NavLink
          to='/contact'
          className='assessmentBtn'
          
        >
          Get Free Assessment
        </NavLink>
      </div>

      <div className='rowColumn section centered'>
        <div className='descContentContainer'>
          <p className='descContentTitle'>About our immigration consultancy</p>
          <p className='countrySubTitle'>
            We've been counselling students for Educational opportunities in
            foreign countries and have been helping them to realise their dream
            of studying abroad.
          </p>
          <p className='descContent'>
            You only have to dream, together we will make it real. Get a better
            guidance and speed up your visa process. Conquer the quest of career
            with us. We are available 24*7 to provide limitless support for
            student to find their way to fly high and catch dream.
          </p>
        </div>
        <img src={countryDescLogo} className='descLogo' />
      </div>

      <div className='universityContainer'>
        <p className='headerText'>Top Global Colleges & Universities</p>
        <div className='universityLogoContainer'>
          {Universities.map((item, index) => {
            return (
              <div className='collegeCard'>
                <img src={item.icon} className='collegeLogo' />
              </div>
            )
          })}
        </div>
      </div>
      <Footer/>
    </div>
  )
}
