export const ReasonUs = [
    {
      title: 'World-class institute.'
    },
    {
      title: 'Wide variety of programs to study'
    },
    {
      title: 'Conversational, broad-based education.'
    },
    {
      title: 'Be highly sought after by employers.'
    },
    {
      title: 'Diversity society.'
    }
  ]
  