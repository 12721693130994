import {
  abroad,
  admission,
  arrow,
  exam,
  gicPayment,
  medical,
  tuitionFeesPayment,
  visa
} from '../Images'

export const JourneySteps = [
  {
    Id: "Step : 1",
    Name: 'IELTS',
    Icon: exam,   
    style:'journeyIcon'
  },
  {
    Icon:arrow,
    style:'arrow'
  },
  {
    Id: "Step : 2",
    Name: 'Admission',
    Icon: admission, 
    style:'journeyIcon'  
  },
  {
    Icon:arrow,
    style:'arrow'
  },
  {
    Id: "Step : 3",
    Name: 'Tuition Fees Payment',
    Icon: tuitionFeesPayment,
    style:'journeyIcon'
  },
  {
    Icon:arrow,
    style:'arrow'
  },
  {
    Id: "Step : 4",
    Name: 'GIC Payment',
    Icon: gicPayment,
    style:'journeyIcon'
  },
  {
    Icon:arrow,
    style:'arrow'
  },
  {
    Id: "Step : 5",
    Name: 'Medical',
    Icon: medical,
    style:'journeyIcon'
  },
  {
    Icon:arrow,
    style:'arrow'
  },
  {
    Id: "Step : 6",
    Name: 'Visa Filing',
    Icon: visa,
    style:'journeyIcon'
  },
  {
    Icon:arrow,
    style:'arrow'
  },
  {
    Id: "Step : 7",
    Name: 'Fly Abroad',
    Icon: abroad,
    style:'journeyIcon'
  }
]
