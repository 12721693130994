export const ReasonCanada = [
    {
      title: 'Live in bilingual environment.'
    },
  
    {
      title: 'Work while studying.'
    },
    {
      title: 'Get a world-class education & scholarship.'
    },
    {
      title: 'Excellent quality of life.'
    },
    {
      title: 'Have a enriching cultural experience.'
    },
    {
      title: "Discover the country's natural beauty."
    },
    {
      title: 'Better employment opportunities after graduation.'
    },
    {
      title: 'Post study work permit.'
    }
  ]
  