import React, { useEffect, useState } from 'react'

import { BrowserRouter, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ReactGa from 'react-ga'

//local imports
import './App.css'

import Home from './Layouts/Home'
import About from './Layouts/About'
import Services from './Layouts/Services'
import Country from './Layouts/Country'
import Contact from './Layouts/Contact'
import Privacy from './Layouts/Privacy'
import Terms from './Layouts/Terms'
import Canada from './Layouts/Canada'
import Uk from './Layouts/Uk'
import Us from './Layouts/Us'
import Footer from './Layouts/Footer'
import Error from './Layouts/Error'
import IELTS from './Layouts/IELTS'




function App() {
  useEffect(() => {
    ReactGa.initialize('UA-260370751-1')
    ReactGa.pageview(window.location.pathname)
  })

  return (   
       <BrowserRouter>   
      <Routes>        
        <Route index   element={<Home />}> </Route>
        <Route  path='about' element={<About />} ></Route>
        <Route path='/services' element={<Services />} ></Route>
        <Route path='/country' element={<Country />} ></Route>
        <Route path='/contact' element={<Contact />} ></Route>
        <Route path='/country/canada' element={<Canada />} ></Route>
        <Route path='/country/uk' element={<Uk />} ></Route>
        <Route path='/country/us' element={<Us />} ></Route>
        <Route path='/privacypolicy' element={<Privacy />} />
        <Route path='/termsofUse' element={<Terms />} />      
       <Route path ='*' element={<Error/>}/>
      </Routes>   
      </BrowserRouter>     
  )
}

export default App
