import React, { useEffect } from "react";
import '../Stylesheet/Legal.css'
import { useLocation } from 'react-router-dom'
import { termsOfUseBannerLogo } from "../res/Images";
import Footer from "./Footer";

export default function Terms() {

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div>
      <div className='legalBanner'>
        <p className='legalBannerText'>TERMS OF USE</p>
        <img src={termsOfUseBannerLogo} className='bannerImage' />
      </div>
      <div className='legalSection'>
        <p className='legalHeader'>User's Terms of Use</p>
        <p className='legalDesc'>
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures. This document is
          published in accordance with the provisions of Rule 3 (1) of the
          Information Technology (Intermediaries guidelines) Rules, 2011 that
          require publishing the rules and regulations, privacy policy and
          Terms of Use for access or usage of domain name www.honestadvisor.in
          (“Website”).
        </p>
        <p className='legalHeader'>Basic Terms</p>
        <p className='legalDesc'>
          The Platform is owned by Honest Advisor under the Companies Act,
          1956 with its registered office at 305 - Siddharth Annexe - II, Sama
          Savli Road, Vadodara, Gujarat. Your use of the Platform and Services
          and tools are governed by the following terms and conditions ("Terms
          of Use") as applicable to the platform including the applicable
          policies which are incorporated herein by way of reference. If You
          transact on the platform, you shall be subject to the policies that
          are applicable to the platform for such transaction. By mere use of
          the platform, you shall be contracting with Honest Advisor and these
          terms and conditions including the policies constitute Your binding
          obligations, with Honest Advisor. Honest Advisor reserves the right
          to change this site and these term and condition at any time. Honest
          Advisor retain the right to deny access to anyone who we believe has
          violated any of this term of use. If you are under 18, you may use
          the website only with the involvement of parent or guardian.
          Accessing, Browsing or otherwise using the site indicates your
          agreement to all the term & condition in this agreement, so please
          read this agreement carefully before proceeding.
        </p>
      </div>
      <Footer />
    </div>
  )
}