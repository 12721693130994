export const NavItems = [
  {
      name: "Home",
      navigate: "/",
      
  },
  {
      name: "About",
      navigate: "/about",
    
  },
  {
      name: "Service",
      navigate: "/services",
     
  },
  {
      name: "Country",
      navigate: "/country",
     
  },
  {
      name: "Contact",
      navigate: "/contact",
     
  },
]