
export const ReasonUk = [
    {
      title: 'Shorter duration of courses.'
    },
    {
      title: 'Work while you learn.'
    },
    {
      title: 'Explore europe while studying.'
    },
    {
      title: 'Health benefits for students.'
    }
  ]
  