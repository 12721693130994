import React, { useEffect, useState, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {
  canadaFlag,
  homeBannerLogo,
  homeDescLogo,
  newsLogo,
  personalGuidance,
  solutions,
  support,
  team,
  ukFlag,
  usFlag
} from '../res/Images'
import Navbar from './Navbar'
import '../Stylesheet/Home.css'
import { JourneySteps } from '../res/Statics/JourneySteps'
import { Articles } from '../res/Statics/Articles'
import Footer from './Footer'

export default function Home () {
  const location = useLocation()
  
  useEffect(() => {
    window.scrollTo(0, 0)
   
  }, [location])



  return (
    <div>
      <Navbar bannerImage={homeBannerLogo}>
        <p className='bannerText'>BE ON THE FLIGHT</p>
        <p className='bannerSecondLineText'>FULL OF CAREER OPPORTUNITIES</p>
        <div className='mobileRow'>
          <NavLink className='blueBgBtn' to='/services'>
            Learn More
          </NavLink>
          <NavLink
            className='blueBorderBtn'
            style={{ marginLeft: 20 }}
            to='/contact'
          >
            Enquiry Now
          </NavLink>
        </div>
      </Navbar>

      <div className='featureContainer'>
        <p className='headerText'>Our Features</p>
        <p className='headerDescText'>
          Let's prepare for your forthcoming future
        </p>
        <div className='feature'>
          <div className='card'>
            <div className='layer'></div>
            <div className='featureContent'>
              <img src={solutions} className='featureIcon' />
              <p className='cardHeaderText'>Transparent Processing</p>
              <p className='cardContentText'>
                Transparent processing with solution to A to Z needs of foreign
                education aspirants
              </p>
            </div>
          </div>

          <div className='card'>
            <div className='layer'></div>
            <p className='featureContent'>
              <img src={team} className='featureIcon' />
              <p className='cardHeaderText'>Technical Sound Team</p>
              <p className='cardContentText'>
                Technically sound team for study in Canada, USA & UK, choose
                from 10,000+ courses
              </p>
            </p>
          </div>

          <div className='card'>
            <div className='layer'></div>
            <p className='featureContent'>
              <img src={support} className='featureIcon' />
              <p className='cardHeaderText'>24*7 Support</p>
              <p className='cardContentText'>
                Young and Enthusiastic team to support & help you 24*7
              </p>
            </p>
          </div>
        </div>
      </div>

      <div className='rowColumn padding centered'>
        <div className='descContentContainer'>
          <p className='descContentTitle'>Step Towards Sparkling Future</p>
          <p className='descContent'>
            If you're looking for a truly life-changing experience. we can offer
            you the study abroad programs that will allow to achieve true
            learning. We are always availed to consult you on taking your higher
            education to the next level in the competitive world. We open the
            world for you. Improve your chances of study abroad with honest
            advices.
          </p>
          <NavLink to='/contact' className='blueBgBtn'>
            Apply Now
          </NavLink>
        </div>
        <img src={homeDescLogo} className='homeDescLogo' />
      </div>

      <div className='countryBenefitContainer'>
        <p className='headerText'>Benefits of Study Abroad</p>
        <p className='headerDescText'>
          Get 50+ Top Global Universities & Courses from
        </p>
        <div className='rowColumn'>
          <div className='benefitCard'>
            <img src={canadaFlag} className='benefitCountryFlag' />
            <div className='benefitCountryTextContainer'>
              <p className='cardHeaderText'>Universities 100+</p>
              <p className='cardHeaderText'>Courses 10,000+</p>
            </div>
          </div>

          <div className='benefitCard'>
            <img src={ukFlag} className='benefitCountryFlag' />
            <div className='benefitCountryTextContainer'>
              <p className='cardHeaderText'>Universities 150+</p>
              <p className='cardHeaderText'>Courses 15,000+</p>
            </div>
          </div>

          <div className='benefitCard'>
            <img src={usFlag} className='benefitCountryFlag' />

            <div className='benefitCountryTextContainer'>
              <p className='cardHeaderText'>Universities 120+</p>
              <p className='cardHeaderText'>Courses 10,000+</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section'>
        <div className='guidanceContainer'>
          <img src={personalGuidance} className='personalGuidanceIcon' />
          <div className='personalizeContainer'>
            <p className='personalizeText'>Get Personalized Guidance</p>

            <NavLink
              to='/contact'
              className='blueBgBtn'
              style={{ textAlign: 'center' }}
            >
              Book Now
            </NavLink>
          </div>
        </div>
      </div>

      <div className='JourneyMainContainer'>
        <p className='headerText'>Student Journey with Honest Advisor</p>
        <p className='headerDescText'>
          Let's take off towards study abroad dream
        </p>
        <div className='journeyContainer'>
          {JourneySteps.map((item, index) => {
            return (
              <div className='journeySection'>
                <img src={item.Icon} className={item.style} />

                <p className='journeyName'>{item.Name}</p>
                <p className='journeyStepText'>{item.Id}</p>
              </div>
            )
          })}
        </div>
      </div>

      <div className='section'>
        <p className='headerText'>Latest Article on Study Abroad</p>
        <p className='headerDescText'>Let's see what is trending these days</p>

        <div className='newsContainer'>
          {Articles.map((item, index) => {
            return (
              <div className='newsCard'>
                <img src={newsLogo} className='newsIcon' />
                <p className='newsTitle'>{item.title}</p>
                <p className='newsHeading'>{item.Heading}</p>
                <p className='newsDesc'>{item.Description}</p>
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </div>
  )
}
