import React from 'react'
import { NavLink } from 'react-router-dom'
import { MdOutlineKeyboardArrowRight, MdCopyright } from 'react-icons/md'
import { IoIosMail, IoIosCall } from 'react-icons/io'
import { ImLocation } from 'react-icons/im'
import { whiteLogo, whiteLogo2 } from '../res/Images'
import { NavItems } from '../res/Statics/NavItems'
import {
  RiWhatsappLine,
  RiInstagramLine,
  RiFacebookCircleLine
} from 'react-icons/ri'
import '../Stylesheet/Footer.css'

export default function Footer () {
  return (
    <body>
     
        <div className='footer'>
          <div className='footerMainContainer'>
            <div>
            <div className='footerLogoContainer'>
            <img src={whiteLogo2} className='whiteLogo' />
         
              <p className='FooterlogoTitle'>
                HonestAdvisor
              </p>            
            
            <p className='FooterlogoDesc'>Your Limitless Support</p>
          </div>
              {/* <img src={whiteLogo} className='whiteLogo' /> */}
              <p className='footerDesc'>
                Helping You Build a Stronger Tomorrow
              </p>
              <div className='contentContainer' style={{ marginTop: 10 }}>
                <div>Follow us :</div>
                <a
                  className='headerSocialIcon'
                  target={'_blank'}
                  href='https://wa.me/919106939841'
                >
                  <RiWhatsappLine color='white' size={17} />
                </a>
                <a
                  className='headerSocialIcon'
                  target={'_blank'}
                  href='https://www.instagram.com/honest_advisor/'
                >
                  <RiInstagramLine color='white' size={17} />
                </a>
                <a
                  className='headerSocialIcon'
                  target={'_blank'}
                  href='https://m.facebook.com/Honest-Advisor-106002771734148/'
                >
                  <RiFacebookCircleLine color='white' size={17} />
                </a>
              </div>
            </div>
            <div>
              <p className='footerHeader'>Quick Links</p>
              <ul>
                {NavItems.map((item, index) => {
                  return (
                    <li key={index} className='quickAction contentContainer'>
                      <MdOutlineKeyboardArrowRight />
                      <NavLink to={item.navigate} className='quickLink'>
                        {item.name}
                      </NavLink>
                    </li>
                  )
                })}
              </ul>
            </div>

            <div>
              <p className='footerHeader'>Legal</p>

              <div className='quickAction contentContainer'>
                <MdOutlineKeyboardArrowRight />
                <NavLink className='quickLink' to='/privacypolicy'>
                  Privacy Policy
                </NavLink>
              </div>
              <div className='quickAction contentContainer'>
                <MdOutlineKeyboardArrowRight />
                <NavLink className='quickLink' to='/termsofUse'>
                  Terms of Use
                </NavLink>
              </div>
            </div>

            <div>
              <p className='footerHeader'>Study In</p>
              <div className='quickAction contentContainer'>
                <MdOutlineKeyboardArrowRight />
                <NavLink to='/country/canada' className='quickLink'>
                  Canada
                </NavLink>
              </div>
              <div className='quickAction contentContainer'>
                <MdOutlineKeyboardArrowRight />
                <NavLink to='/country/uk' className='quickLink'>
                  UK
                </NavLink>
              </div>
              <div className='quickAction contentContainer'>
                <MdOutlineKeyboardArrowRight />
                <NavLink to='/country/us' className='quickLink'>
                  US
                </NavLink>
              </div>
            </div>

            <div>
              <p className='footerHeader'>Contact</p>

              <div className='contentContainer'>
                <IoIosMail size={20} />
                <p className='footerContent' style={{ marginLeft: 20 }}>
                  info@honestadvisor.com
                </p>
              </div>
              <div className='contentContainer' style={{ paddingTop: 10 }}>
                <IoIosCall size={20} />
                <p className='footerContent' style={{ marginLeft: 20 }}>
                  +1 (647) 831 6153, +91 910 693 9841
                </p>
              </div>

              <div className='addressContainer' style={{ paddingTop: 10 }}>
                <ImLocation size={20} style={{ marginTop: 9 }} />
                <div>
                  <p className='footerContent' style={{ marginLeft: 20 }}>
                    305 - Siddharth Annexe - II,
                  </p>
                  <p className='footerContent' style={{ marginLeft: 20 }}>
                    Sama Savli Road, Vadodara.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr className='footerLine' />
          <p className='copyright'>
            <p className='copyrightText'>Copyright</p> <MdCopyright />
            <p style={{ marginLeft: 10 }} className='copyrightText'>
              {' '}
              2021 Honest Advisor. All right reserved.
            </p>
          </p>
        </div>
     
    </body>
  )
}
