import React,{useEffect} from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {
  aboutBannerLogo,
  aboutDescLogo,
  meetDescLogo,
  missionLogo,
  spouseVisa,
  studentVisa,
  touristVisa,
  visionLogo,
  visitorVisa,
  Wallpaper
} from '../res/Images'
import '../Stylesheet/About.css'
import Footer from './Footer'
import Navbar from './Navbar'

export default function About () {

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className='bannerHeader'>
      <Navbar bannerImage={aboutBannerLogo}>
        <p className='bannerText'>SHAPE YOUR FUTURE WITH</p>
        <p className='bannerText'>THE HONEST ADVICES</p>
      </Navbar>

      <div className='rowColumn section centered'>
        <div className='descContentContainer'>
          <p className='descContentTitle'>Who We Are</p>
          <p className='descContent'>
            A Honest Advisor is founded by two young friends having experience
            of more than 7 years in the field of student visa in order to help
            student to fulfil their dream. Our team has knowledge of 10,000+
            courses, admission requirements, Visa Process and Post Visa
            Information for 100+ colleges or universities across countries like
            Canada and UK. Further We provide Spouse Visa, Tourist Visa, Work
            Permit & Permanent Residency services.
          </p>
        </div>
        <img src={aboutDescLogo} className='descLogo' />
      </div>

      <div className='section'>
        <p className='headerText'>OUR</p>
        <p className='headerText'>VISION & MISSION</p>

        <div className='valuesContainer'>
          <div className='valuesCard'>
            <img src={visionLogo} className='valueIcon' />
            <p className='cardHeaderText'>Vision</p>
            <p className='valuesContent'>
              Our vision is to give limitless support to the students with end
              to end solutions throughout their student visa journey.
            </p>
          </div>
          <div className='valuesCard'>
            <img src={missionLogo} className='valueIcon' />
            <p className='cardHeaderText'>Mission</p>
            <p className='valuesContent'>
              Our mission is to make the student visa process easy for parents
              and students. Making their journey of study aboard hassle-free.
            </p>
          </div>
        </div>
      </div>

    <div className='serviceContainer'>
        <p className='headerDescText'>Services</p>
        <p className='headerText' 
        style={{ marginBottom: 20 }}
        >
          Explore What We Do
        </p>
      
          <div className='service'>
            <div className='card'>
              <div className='layer'> </div>
              <div className='serviceContent'>
                <img src={studentVisa} className='serviceIcon' />
                <p className='cardHeaderText'>Student Visa</p>
                <p className='cardContentText'>
                  Step towards breakthrough career
                </p>
              </div>
            </div>

            <div className='card'>
              <div className='layer'></div>
              <div className='serviceContent'>
                <img src={visitorVisa} className='serviceIcon' />
                <p className='cardHeaderText'>Visitor Visa</p>
                <p className='cardContentText'>
                  Take your family member abroad
                </p>
              </div>
            </div>
         

          
            <div className='card'>
              <div className='layer'> </div>
              <div className='serviceContent'>
                <img src={spouseVisa} className='serviceIcon' />
                <p className='cardHeaderText'>Spouse Visa</p>
                <p className='cardContentText'>Take your spouse along</p>
              </div>
              </div>

            <div className='card'>
              <div className='layer'> </div>
              <div className='serviceContent'>
                <img src={touristVisa} className='serviceIcon' />
                <p className='cardHeaderText'>Tourist Visa</p>
                <p className='cardContentText'>Plan your summer vacation</p>
              </div>
            </div>
          </div>
        </div>
       
     
      <div className='rowColumn centered'>
        <div className='mobileCenter'>
          <p className='descContentTitle'>Meet Our Honest Advisors</p>
          <p className='meetText' style={{ marginTop: 30 }}>
            Call us at{' '}
            <span style={{ color: '#0f0d72', fontWeight: 'bold' }}>
              +1 (647) 877-6153, +91 9106939841
            </span>
          </p>
          <p className='meetText'>OR</p>
          <p className='meetText' style={{ marginBottom: 30 }}>
            Mail us at{' '}
            <span style={{ color: '#0f0d72', fontWeight: 'bold' }}>
              info@honestadvisor.in
            </span>
          </p>
          <NavLink to='/contact' className='blueBgBtn'>
            Apply Now
          </NavLink>
        </div>
        <img src={meetDescLogo} className='descLogo' />
      </div>
      <Footer />
    </div>
  )
}
