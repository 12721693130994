import { accomodation, admissionGuidelines, certificate, counselling, courseSuggestion, educationLoan, forex, tuitionFees, visaFile } from '../Images'
  
  export const ServiceItems = [
    {
      Name: 'Career Counseling',
      Icon: counselling,
      Desc: 'Achieve your goal with our experts'
    },
    {
      Name: 'Course Suggestion',
      Icon: courseSuggestion,
      Desc: 'Choose best suitable course from 300+ institutions'
    },
    {
      Name: 'Admission Guidlines',
      Icon: admissionGuidelines,
      Desc: 'Get complete admission guideline from the experts'
    },
    {
      Name: 'Tuition Fees Payment',
      Icon: tuitionFees,
      Desc: 'Do efficient payment & save time'
    },
    {
      Name: 'Guarantee Investment Certificate',
      Icon: certificate,
      Desc: 'Open GIC account and make payment smoothly'
    },
    {
      Name: 'Visa Filing',
      Icon: visaFile,
      Desc: 'Get guidance in filling visa forms without any error'
    },
    {
      Name: 'Education Loan',
      Icon: educationLoan,
      Desc: 'Get education loan quickly from India’s best organisation'
    },
    {
      Name: 'Forex',
      Icon: forex,
      Desc: 'Get offer on forex services with lowest rates'
    },
    {
      Name: 'Accomodation',
      Icon: accomodation,
      Desc: 'Get accomodate easily in foreign country'
    }
  ]
  