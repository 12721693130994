import React, { useState, useEffect } from 'react'
import { contactBannerLogo } from "../res/Images";
import '../Stylesheet/Contact.css'
import Navbar from "./Navbar";
import emailjs from 'emailjs-com'
import { useLocation } from 'react-router-dom'
import { MdLocationOn, MdPhoneIphone, MdEmail } from 'react-icons/md'
import { RiWhatsappLine, RiInstagramLine, RiFacebookFill } from 'react-icons/ri'
import Footer from './Footer';

export default function Contact () {

  const location = useLocation()
  const [Loading, setLoading] = useState(false)
  const [Name, setName] = useState('')
  const [NameError, setNameError] = useState('')
  const [Email, setEmail] = useState('')
  const [EmailError, setEmailError] = useState('')
  const [Contact, setContact] = useState('')
  const [ContactError, setContactError] = useState('')
  const [Subject, setSubject] = useState('')
  const [SubjectError, setSubjectError] = useState('')
  const [Comment, setComment] = useState('')
  const [CommentError, setCommentError] = useState('')

  const emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const isEmailValid = emailValidate.test(Email)
  const contactValidate = /^[6-9]\d{9}$/
  const isContactValidate = contactValidate.test(Contact)

 

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const handleSubmit = e => {
    e.preventDefault()

    if (
      Name.trim().length !== 0 &&
      Email.trim().length !== 0 &&
      isEmailValid &&
      isContactValidate &&
      Contact.trim().length !== 0 &&
      Subject.trim().length !== 0 &&
      Comment.trim().length !== 0
    ) {
      emailjs
        .sendForm(
          'service_wdns4g9',
          'template_ln1yxne',
          e.target,
          'user_YGp7fuAZYW2RRZVIpdUbM'
        )
        .then(res => {
          alert(
            'Thank you so much for contact us, our team will contact you soon'
          )
          setName('')
          setEmail('')
          setContact('')
          setSubject('')
          setComment('')
          console.log('response==>', res)
        })
        .catch(e => {
          console.log('catch===>', e)
        })
    }

    if (Name.trim().length === 0) {
      setNameError('Required')
    } else {
      setNameError('')
    }

    if (Email.trim().length === 0) {
      setEmailError('Required')
    } else if (!isEmailValid) {
      setEmailError('Invalid Email')
    } else {
      setEmailError('')
    }

    if (Contact.trim().length === 0) {
      setContactError('Required')
    } else if (!isContactValidate) {
      setContactError('Invalid Contact')
    } else {
      setContactError('')
    }

    if (Subject.trim().length === 0) {
      setSubjectError('Required')
    } else {
      setSubjectError('')
    }

    if (Comment.trim().length === 0) {
      setCommentError('Required')
    } else {
      setCommentError('')
    }
  }

  const validateName = () => {
    if (Name.trim().length === 0) {
      setNameError('Required')
    } else {
      setNameError('')
    }
  }

  const validateEmail = () => {
    if (Email.trim().length === 0) {
      setEmailError('Required')
    } else if (!isEmailValid) {
      setEmailError('Invalid Email')
    } else {
      setEmailError('')
    }
  }

  const validateContact = () => {
    if (Contact.trim().length === 0) {
      setContactError('Required')
    } else if (!isContactValidate) {
      setContactError('Invalid Contact')
    } else {
      setContactError('')
    }
  }

  const validateSubject = () => {
    if (Subject.trim().length === 0) {
      setSubjectError('Required')
    } else {
      setSubjectError('')
    }
  }

  const validateComment = () => {
    if (Comment.trim().length === 0) {
      setCommentError('Required')
    } else {
      setCommentError('')
    }
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    window.scrollTo(0, 0)
  }, [location])

  // if (Loading) {
  //   return (
  //     <div className='loading'>
  //       <BeatLoader size={20} color='#246da4' loading={Loading} />
  //     </div>
  //   )
  // }

  return (
    <div>
       <Navbar bannerImage={contactBannerLogo}>
        <p className='bannerText'>OPEN YOUR WINGS TO CREATE,</p>
        <p className='bannerText'>YOUR OWN DESTINATION</p>
      </Navbar>

      <div className='section'>
          <p className='headerText'>Get In Touch With Us</p>
          <p className='contactHeaderDesc'>
            Are you looking for life-changing experience ? If so, contact us
            now.
          </p>

          <div className='rowColumn' >
            <div className='contactDetails'>
              <MdPhoneIphone color='#0f0d72' size={35} />
              <p className='contactHeader'>PHONE</p>
              <p className='contactInfo'>Phone - +1 (647) 877 - 6153</p>
              <p className='contactInfo'>Phone - +91 910 693 9841</p>
            </div>
            <div className='contactSeparator'></div>
            <div className='contactDetails'>
              <MdEmail color='#0f0d72' size={35} />
              <p className='contactHeader'>EMAIL</p>
              <p className='contactInfo'>info@honestadvisor.com</p>
            </div>
            <div className='contactSeparator'></div>
            <div className='contactDetails'>
              <MdLocationOn color='#0f0d72' size={35} />
              <p className='contactHeader'>ADDRESS</p>
              <p className='contactInfo'>305 - Siddharth Annexe - II,</p>
              <p className='contactInfo'>Sama Savli Road, Vadodara.</p>
            </div>
          </div>
        </div>

        <div className='formContainer'>
          <p className='formTitle'>IF YOU HAVE ANY QUERY</p>
          <p className='formTitle' style={{ marginBottom: 50 }}>
            PLEASE DO NOT HESITATE TO ASK US
          </p>
          <form onSubmit={handleSubmit}>
            <table width='100%'>
              <tr>
                <td>
                  <p className='fieldLabel'>Name</p>
                  <input
                    placeholder='Enter Your Name'
                    name='fullname'
                    className='fieldInput'
                    value={Name}
                    type='text'
                    onChange={e => setName(e.target.value)}
                    onBlur={e => validateName()}
                  />
                </td>
              </tr>
              <tr>
                <td>
                <div className='error'>{NameError}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className='fieldLabel'>Email</p>
                  <input
                    placeholder='Enter Your Email'
                    name='email'
                    className='fieldInput'
                    value={Email}
                    type='text'
                    onChange={e => setEmail(e.target.value)}
                    onBlur={() => validateEmail()}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className='error'>{EmailError}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className='fieldLabel'>Contact</p>
                  <input
                    placeholder='Enter Your Contact'
                    name='contact'
                    className='fieldInput'
                    value={Contact}
                    type='text'
                    onChange={e => setContact(e.target.value)}
                    onBlur={() => validateContact()}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className='error'>{ContactError}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className='fieldLabel'>Subject</p>
                  <input
                    placeholder='Enter Your Subject'
                    className='fieldInput'
                    name='subject'
                    value={Subject}
                    onChange={e => setSubject(e.target.value)}
                    onBlur={() => validateSubject()}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className='error'>{SubjectError}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className='fieldLabel'>Comment</p>
                  <textarea
                    maxLength={300}
                    name='comment'
                    rows={5}
                    value={Comment}
                    onChange={e => setComment(e.target.value)}
                    placeholder='Comment'
                    multiple={true}
                    className='fieldInput'
                    onBlur={() => validateComment()}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className='error'>{CommentError}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <input type='submit' value='Submit' className='submitBtn' />
                </td>
              </tr>
            </table>
          </form>
        </div>

        <div >
          <p className='headerText' style={{ margin: 20 }}>
            Connect With Us
          </p>
          <div className='socialContainer'>
            <a className='headerSocialIcon' href='https://wa.me/919106939841'>
              <RiWhatsappLine
                className='socialmediaBox'
                size={40}
                color='white'
              />
            </a>
            <a
              className='headerSocialIcon'
              href='https://m.facebook.com/Honest-Advisor-106002771734148/'
            >
              <RiFacebookFill
                className='socialmediaBox'
                size={40}
                color='white'
              />
            </a>

            <a
              className='headerSocialIcon'
              href='https://www.instagram.com/honest_advisor/'
            >
              <RiInstagramLine
                className='socialmediaBox'
                size={40}
                color='white'
              />
            </a>
          </div>
        </div>
        <Footer />
      </div>
   
  )
}
