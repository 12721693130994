import React, { useState } from 'react'
import { RiMenu3Line, RiCloseFill } from 'react-icons/ri'
import {
  RiWhatsappLine,
  RiInstagramLine,
  RiFacebookCircleLine
} from 'react-icons/ri'
import { IoIosMail, IoIosCall } from 'react-icons/io'
import { NavLink } from 'react-router-dom'

//local imports
import '../Stylesheet/Navbar.css'
import { NavItems } from '../res/Statics/NavItems'
import { whiteLogo, whiteLogo2 } from '../res/Images'

export default function Navbar (props) {
  const [IsMobile, setIsMobile] = useState(false)
  return (
    <div>
      <div className='headerInfoContainer'>
        <div className='row'>
          <IoIosMail size={18} color='#f2f2f2' />
          <div style={{ marginLeft: 10 }}>info@honestadvisor.in</div>
          <div className='vl'></div>
          <IoIosCall size={18} color='#f2f2f2' />
          <div style={{ marginLeft: 10 }}>
            +1 (647) 877 - 6153, +91 910 693 9841
          </div>
        </div>
        <div className='row'>
          <p>Follow us : </p>
          <a
            className='headerSocialIcon'
            href='https://wa.me/919106939841'
            target={'_blank'}
          >
            <RiWhatsappLine size={17} className='headerSocialIcon' />
          </a>
          <a href='https://www.instagram.com/honest_advisor/' target={'_blank'}>
            <RiInstagramLine size={17} className='headerSocialIcon' />
          </a>
          <a
            href='https://m.facebook.com/Honest-Advisor-106002771734148/'
            target={'_blank'}
          >
            <RiFacebookCircleLine size={17} className='headerSocialIcon' />
          </a>
        </div>
      </div>
      <div className='bannerBackground'>
        <nav className='navbar'>
          <div className='logoContainer'>
            <img src={whiteLogo2} className='logo' />
            <div className='row'>
              <p className='logoTitle'>
                HonestAdvisor
              </p>            
            </div>
            <p className='logoDesc'>Your Limitless Support</p>
          </div>
          <ul
            onClick={() => setIsMobile(false)}
            className={IsMobile ? 'mobile-nav-menu' : 'nav-menu'}
          >
            {NavItems.map((item, index) => {
              return (
                <li key={index} className='nav-item'>
                  <NavLink
                    to={item.navigate}
                    activeClassName='active'
                    className='nav-link'
                  >
                    {item.name}
                  </NavLink>
                </li>
              )
            })}
          </ul>
          <div
            onClick={() => setIsMobile(!IsMobile)}
            className='mobile-menu-icon'
          >
            {IsMobile ? (
              <RiCloseFill color='white' />
            ) : (
              <RiMenu3Line color='white' />
            )}
          </div>
        </nav>
        <div className='bannerSection'>
          <div>{props.children}</div>
          <img src={props.bannerImage} className='bannerImage' />
        </div>
      </div>

      <div>
        <div>{props.desc}</div>
      </div>

    
    </div>
  )
}
