export const Articles = [
    {
        title:'Canada Update',
        Description:'IRCC to resume FSWP and CEC invitations in july and offer open work permits to PGWP holders for Canadian immigration candidates.'
    },
    {
      title: 'Canada Update',
    
      Heading:
        'Canada increases target to 432,000 immigrants in 2022 under immigration levels plan 2022-2024.',
      Description:
        'Canada has set an even higher bar, as it will look to welcome 451,000 new immigrants by 2024. In a statement to CIC News, Immigration Minister Sean Fraser explained the rational for the new levels plan.',
      source: 'https://www.cicnews.com/'
    },
    {
      title: 'Canada Update',
      tagline: 'Effective January 28, 2022',
      Description:
        'Canada has removed the modified pre-departure COVID-19 test requirement for travellers on direct flights to Canada from India and Morocco, and the requirement for third country testing for traveller on indirect routes to Canada from these countries.',
      source:
        'https://mobile.twitter.com/transport_gc/status/1487121389177823233?s=24'
    }
  ]
  