import React, { useState, useEffect } from 'react'
import { RiCheckFill } from 'react-icons/ri'
import { ReasonUs } from '../res/Statics/ReasonUs'
import {
  aboutUsLogo,
  studyVisa,
  usBannerLogo,
  usBenefits,
  visitorVisa
} from '../res/Images'
import Navbar from './Navbar'
import '../Stylesheet/Country.css'
import { NavLink, useLocation } from 'react-router-dom'
import Footer from './Footer'

export default function Us () {
  const location = useLocation()
  const [Loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div>
      <Navbar bannerImage={usBannerLogo}>
        <p className='bannerText'>STUDY IN UNITED STATE</p>
        <p className='bannerSecondLineText' >
          BEGIN YOUR APPLICATION TODAY
        </p>
        <NavLink className='paddingblueBgBtn' to='/contact'>
          Begin
        </NavLink>
      </Navbar>

      <div className='rowColumn section centered'>
        <div className='descContentContainer'>
          <p className='descContentTitle'>About United State</p>
          <p className='descContent'>
            The United States is known for its higher education opportunities,
            which explains why so many American students earn certificates,
            associate, bachelor’s, master’s and doctorate degrees each year.The
            United States of America (USA) hosts the most number of
            international students in the world. Quality education, unique
            curriculum, multicultural environment, and abundant opportunities
            are just some of the reasons why many Indian students want to study
            in the US.
          </p>
        </div>
        <img src={aboutUsLogo} className='descLogo' />
      </div>

      <div className='serviceContainer'>
        <p className='headerDescText'>Services</p>
        <p className='headerText' style={{ marginBottom: 20 }}>
          Explore What We Do
        </p>

        <div className='feature'>
          <div className='card'>
            <div className='layer'> </div>
            <div className='featureContent'>
              <img src={studyVisa} className='featureIcon' />
              <p className='cardHeaderText'>Study Visa</p>
              <p className='cardContentText'>
              Study and settle in US with their Study to PR pathways
              </p>
            </div>
          </div>

          <div className='card'>
            <div className='layer'></div>
            <div className='featureContent'>
              <img src={visitorVisa} className='featureIcon' />
              <p className='cardHeaderText'>Visitor Visa</p>
              <p className='cardContentText'>
                Meet your family member or explore the beautiful nature of
                US
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='benefitContainer'>
        <img src={usBenefits} className='descLogo' />
        <div className='descContentContainer'>
          <div>
            <p className='benefitSubtitle'>UNLOCK YOUR CAREER DREAM</p>
            <p className='descContentTitle'>Reasons to study in US</p>

            <p className='descContent'>
              One of the most attractive features of the U.S. higher education
              system is the flexibility it provides through the number and
              diversity of institution types it encompasses.
            </p>
          </div>
          {ReasonUs.map((item, index) => {
            return (
              <div className='reasonContainer'>
                <RiCheckFill
                  className='checkIcon'
                  color='#0f0d72ca'
                  size={20}
                />
                <p className='reasonText'>{item.title}</p>
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </div>
  )
}
