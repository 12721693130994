import { airTicket, document, educationLoan, forex, guideline, insurance, simCard, visaFile } from "../Images";

export const Perks = [
  {
    title: 'Precise Document work',
    icon:document
  },

  {
    title: 'Education Loan Assistance',
    icon:educationLoan
  },
  {
    title: 'Accurate visa filing process',
    icon:visaFile
    
  },
  {
    title: 'Air Ticket',
    icon:airTicket
  },
  {
    title: 'International SIM Card',
    icon:simCard
  },
  {
    title: 'Travel Insurance',
    icon:insurance
  },
  {
    title: 'Forex services',
    icon:forex    
  },
  {
    title: 'Pre-Departure guidelines',
    icon:guideline
  }
]
